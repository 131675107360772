import axios from "axios";
import { APIUrl } from "../apiConstants";

export const getUsersAPI = (payload: any) => {
  let params = "";
  let index = 0;
  for (const param of Object.keys(payload)) {
    if (payload[param] !== undefined && payload[param] !== null) {
      if (index === 0) {
        params = `?${param}=${payload[param]}`;
      } else {
        params += `&${param}=${payload[param]}`;
      }
      index++;
    }
  }
  return axios.get(`${APIUrl.allUsers}${params}`, {
    timeout: 10000,
  });
};

export const createLoginUrlAPI = (id: number) => {
  return axios.get(`${APIUrl.userAutoLogin}/${id}`, {
    timeout: 10000,
  });
};

export const getUserDetailsAPI = (id: number) => {
  return axios.get(`${APIUrl.allUsers}/${id}`, {
    timeout: 10000,
  });
};

export const getAllPhoneNumbersAPI = (id: number) => {
  return axios.get(`${APIUrl.allPhonenumbers}/${id}`, {
    timeout: 10000,
  });
};

export const getUserBillDetailsAPI = (id: number) => {
  return axios.get(`${APIUrl.allUsers}/${id}/bills`, {});
};

export const changeUserPasswordAPI = (id: number, password: string) => {
  return axios.post(
    `${APIUrl.allUsers}/change-password`,
    { id, password },
    {
      timeout: 10000,
    }
  );
};

export const getNewCard = (id: number) => {
  return axios.get(APIUrl.getCard.replace(":id", `${id}`), {
    timeout: 10000,
  });
};

export const getCredits = (id: number) => {
  return axios.get(APIUrl.credits.replace(":id", `${id}`), {
    timeout: 10000,
  });
};

export const getAllPlans = (id: number) => {
  return axios.get(APIUrl.getAllPlans.replace(":id", `${id}`), {
    timeout: 10000,
  });
};

export const getLastInvoice = (id: number) => {
  return axios.get(APIUrl.lastInvoice.replace(":id", `${id}`), {
    timeout: 10000,
  });
};

export const changeExpiryDate = (id: number, expiryDate: string) => {
  return axios.post(
    APIUrl.changeExpiryDate.replace(":id", `${id}`),
    { expiry_date: expiryDate },
    {
      timeout: 10000,
    }
  );
};

export const updatePlan = (planId: number, id: number) => {
  return axios.post(
    APIUrl.updatePlan,
    { plan_id: planId, user: id },
    {
      timeout: 10000,
    }
  );
};

export const getHostedNumberAPI = (id: number, phoneId: number) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(APIUrl.hostedNumber.replace(":id", `${id}`) + '/' + phoneId, {
    headers,
    timeout: 10000,
  });
};

export const verifyHostedNumber = (id: number) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(APIUrl.hostedNumber.replace(":id", `${id}`) + "/verify", {
    headers,
    timeout: 10000,
  });
};

export const changeUserStatusAPI = (payload: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(APIUrl.changeStatus, payload, {
    headers,
    timeout: 10000,
  });
};

export const suspendUserAccountAPI = (id: number) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(APIUrl.suspendUser.replace(":id", `${id}`), {
    headers,
    timeout: 10000,
  });
};

export const getAdHocChargesAPI = (id: number) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(APIUrl.adhocCharges.replace(":id", `${id}`), {
    headers,
    timeout: 10000,
  });
};

export const createUpdateAdHocChargesAPI = (payload: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  let url = APIUrl.adhocCharges;
  if (payload.id) {
    url = `${url.replace(":id", payload.user_id)}/${payload.id}`;
    return axios.patch(url, payload, {
      headers,
      timeout: 10000,
    });
  } else {
    url = `${url.replace(":id", payload.user_id)}`;
    return axios.post(url, payload, {
      headers,
      timeout: 10000,
    });
  }
};

export const resetBrandAPI = (id: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(APIUrl.resetBrand.replace(":id", id), {
    headers,
    timeout: 10000,
  });
};

export const resetCampaignAPI = (id: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(APIUrl.resetCampaign.replace(":id", id), {
    headers,
    timeout: 10000,
  });
};

import React from 'react';
import LoginConfig from '../pages/NoAuth/Login/LoginConfigs';
import TextRootsUtils from '../modules/utils/Utils';
import { Navigate } from 'react-router-dom';
import DashboardConfig from '../pages/Auth/Dashboard/DashboardConfigs';

const routeConfigs = [
    DashboardConfig,
];

const noAuthRouteConfigs = [LoginConfig];

export const authRoutes = [
    ...TextRootsUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '*',
        exact: true,
        isPrivate: false,
        component: () => <Navigate to="/" />,
    },
];
export const customRoute = (path: string, navigationPath: string) => ({
    path: path,
    exact: true,
    isPrivate: false,
    component: () => <Navigate to={navigationPath}/>,
});
export const noAuthRoutes = [
    ...TextRootsUtils.generateRoutesFromConfigs(noAuthRouteConfigs),
    {
        path: '*',
        exact: true,
        isPrivate: false,
        component: () => <Navigate to="/" />,
    },
];

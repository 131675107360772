import { SagaIterator } from "redux-saga/index";
import { ISagaAction } from "../types";
import { put, call, select } from "redux-saga/effects";
import * as actions from "../actions";
import {getSelectedPhoneNumber, getUsersCurrentPage, getUsersPerPage} from "../selectors";
import {
  getUsersAPI,
  createLoginUrlAPI,
  getUserDetailsAPI,
  getUserBillDetailsAPI,
  changeUserPasswordAPI,
  getNewCard,
  getCredits,
  getAllPlans,
  getLastInvoice,
  updatePlan,
  changeExpiryDate,
  getHostedNumberAPI,
  changeUserStatusAPI,
  getAdHocChargesAPI,
  createUpdateAdHocChargesAPI,
  verifyHostedNumber,
  resetBrandAPI, resetCampaignAPI, suspendUserAccountAPI, getAllPhoneNumbersAPI
} from "../utils/Apis/users";
import { showAlert } from "../utils/AlertUtils";
import { forgotPasswordApi } from "../utils/Apis/user";

export const getAllUsers = function* (action: ISagaAction<any>): SagaIterator {
  try {
    const { filterBy, searchText } = action.payload;
    const page = yield select(getUsersCurrentPage);
    const limit = yield select(getUsersPerPage);
    const result = yield call(getUsersAPI, {
      page,
      limit,
      filterBy,
      searchText,
    });
    yield put(actions.setUsers(result.data.data.rows));
    yield put(actions.setTotalUsersRecords(result.data.data.count));
    yield put(actions.setTotalAddressRecords(result.data.data.address_count));
    yield put(actions.setTotalSubUserRecords(result.data.data.sub_users_count));
    yield put(actions.toggleUserLoader(false));
  } catch (e) {
    yield put(actions.toggleUserLoader(false));
    console.log(e);
  }
};

export const createLoginUrl = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    const { id } = action.payload;
    const result = yield call(createLoginUrlAPI, id);
    if (result.data.data.url) {
      window.open(result.data.data.url);
    } else {
      window.open(
        `https://staging.trutextapp.com/auto-login/${encodeURIComponent(
          result.data.data.code
        )}`
      );
    }
    yield put(actions.toggleUserLoader(false));
  } catch (e: any) {
    yield put(actions.toggleUserLoader(false));
    console.log(e);
    if (e !== "Session expired") {
      const data = {
        title: "Error!",
        text:
          e?.response?.data?.error ||
          e?.response?.data?.errors ||
          e?.response?.data?.message,
        icon: "error",
        confirmButtonText: "Okay",
      };
      yield call(showAlert, data);
    }
  }
};

export const getUserDetails = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    const { id } = action.payload;
    const result = yield call(getUserDetailsAPI, id);
    yield put(actions.setSelectedUser(result.data.data));
    yield put(actions.toggleUserLoader(false));
  } catch (e: any) {
    console.log(e);
    yield put(actions.toggleUserLoader(false));
    if (e !== "Session expired") {
      const data = {
        title: "Error!",
        text:
          e?.response?.data?.error ||
          e?.response?.data?.errors ||
          e?.response?.data?.message,
        icon: "error",
        confirmButtonText: "Okay",
      };
      yield call(showAlert, data);
    }
  }
  yield put(actions.toggleUserLoader(false));
};

export const getAllPhoneNumbers = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    const { id } = action.payload;
    const result = yield call(getAllPhoneNumbersAPI, id);
    yield put(actions.updateMyPhoneNumbers(result.data.data));
    yield put(actions.toggleUserLoader(false));
  } catch (e: any) {
    console.log(e);
    yield put(actions.toggleUserLoader(false));
    if (e !== "Session expired") {
      const data = {
        title: "Error!",
        text:
          e?.response?.data?.error ||
          e?.response?.data?.errors ||
          e?.response?.data?.message,
        icon: "error",
        confirmButtonText: "Okay",
      };
      yield call(showAlert, data);
    }
  }
  yield put(actions.toggleUserLoader(false));
};

export const getUserBills = function* (action: ISagaAction<any>): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    const { id } = action.payload;
    const result = yield call(getUserBillDetailsAPI, id);
    yield put(actions.setSelectedUserBill(result.data.data));
    yield put(actions.toggleUserLoader(false));
  } catch (e: any) {
    yield put(actions.toggleUserLoader(false));
    console.log(e);
    if (e !== "Session expired") {
      const data = {
        title: "Error!",
        text:
          e?.response?.data?.error ||
          e?.response?.data?.errors ||
          e?.response?.data?.message,
        icon: "error",
        confirmButtonText: "Okay",
      };
      yield call(showAlert, data);
    }
  }
};

export const changeUserPassword = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    const { id, password } = action.payload;
    const result = yield call(changeUserPasswordAPI, id, password);
    const data = {
      title: "Success!",
      text: result.data.message,
      icon: "success",
      confirmButtonText: "Okay",
    };
    yield put(actions.toggleUserLoader(false));
    yield call(showAlert, data);
  } catch (e: any) {
    yield put(actions.toggleUserLoader(false));
    console.log(e);
    if (e !== "Session expired") {
      const data = {
        title: "Error!",
        text:
          e?.response?.data?.error ||
          e?.response?.data?.errors ||
          e?.response?.data?.message,
        icon: "error",
        confirmButtonText: "Okay",
      };
      yield call(showAlert, data);
    }
  }
};
export const getNewCardAPI = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const result = yield call(getNewCard, action.payload.id);
    // console.log(result);
    yield put(actions.setPrefetchedCardData(result.data.data));
  } catch (e: any) {
    console.log(e);
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
    // alert(e?.response?.data?.errors);
  }
};

export const getCreditsAPI = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const result = yield call(getCredits, action.payload.id);
    yield put(actions.setCredits(parseFloat(result.data.data.credits)));
  } catch (e: any) {
    console.log(e);
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
    // alert(e?.response?.data?.errors);
  }
};
export const getAllPlansAPI = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const result = yield call(getAllPlans, action.payload.id);
    yield put(actions.setAllPlans(result.data.data));
  } catch (e: any) {
    console.log(e);
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
    // alert(e?.response?.data?.errors);
  }
};

export const updatePlanAPI = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield call(updatePlan, action.payload.plan_id, action.payload.id);
    yield put(actions.getAllPlans(action.payload.id));
    const data = {
      title: "Success!",
      text: "Plan successfully changed!",
      icon: "success",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
    yield put(actions.togglePlanchooserModal(false));
    yield put(actions.getCardDetails({ id: action.payload.id }));
    yield put(actions.getCredits({ id: action.payload.id }));
    yield put(actions.getLastInvoiceAction({ id: action.payload.id }));
  } catch (e: any) {
    console.log(e);
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
    // alert(e?.response?.data?.errors);
  }
};

export const getLastInvoiceAPI = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const result = yield call(getLastInvoice, action.payload.id);
    yield put(actions.setLastInvoice(result.data.data));
  } catch (e: any) {
    console.log(e);
    yield put(actions.setLastInvoice(null));
  }
};

export const changeExpiryDateAPI = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const result = yield call(
      changeExpiryDate,
      action.payload.id,
      action.payload.expiryDate
    );
    const data = {
      title: "Success!",
      text: result.data.message,
      icon: "success",
      confirmButtonText: "Okay",
    };
    yield put(actions.toggleChangeExpiryDateModal(false));
    yield put(actions.getAllPlans({ id: action.payload.id }));
    yield call(showAlert, data);
  } catch (e: any) {
    console.log(e);
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  }
};
export const forgotPasswordSaga = function* (action: ISagaAction<any>): SagaIterator {
  try {
    const { email } = action.payload;
    yield call(forgotPasswordApi, { email });
    const data = {
      title: 'Sucess!',
      text: 'Password reset link has successfully sent to registered email.',
      icon: 'success',
      confirmButtonText: 'Okay',
    };
    yield call(showAlert, data);
  } catch (e: any) {
    console.log(e);
    if (e !== 'Session expired') {
      const data = {
        title: 'Error!',
        text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
        icon: 'error',
        confirmButtonText: 'Okay',
      };
      yield call(showAlert, data);
    }
  }
};
export const getHostedNumber = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    const phoneId = yield select(getSelectedPhoneNumber);
    const result = yield call(getHostedNumberAPI, action.payload.id, phoneId);
    yield put(actions.updateHostedNumberData(result.data.data));
    yield put(actions.toggleUserLoader(false));
  } catch (e) {
    console.log(e);
    yield put(actions.toggleUserLoader(false));
  }
};

export const checkAndVerifyHostedNumber = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    const result = yield call(verifyHostedNumber, action.payload.id);
    yield put(actions.toggleUserLoader(false));
    const data = {
      title: "Success!",
      text: result?.data?.message || "Hostednumber successfully verified.",
      icon: "success",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  } catch (e: any) {
    console.log(e);
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
    yield put(actions.toggleUserLoader(false));
  }
};

export const changeUserStatus = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const data = {
      title: "Caution!",
      text: `Are you sure you want to ${
        action.payload.status === "ACTIVE" ? "activate" : "deactivate"
      } this user?`,
      icon: "warning",
      confirmButtonText: "Yes",
      denyButtonText: "No",
    };
    const alertData = yield call(showAlert, data);
    if (alertData.isConfirmed) {
      yield put(actions.toggleUserLoader(true));
      yield call(changeUserStatusAPI, action.payload);
      yield put(actions.fetchUserDetails({ id: action.payload.id }));
      yield put(actions.toggleUserLoader(false));
      const data = {
        title: "Success!",
        text: `User successfully ${
          action.payload.status === "ACTIVE" ? "Activated" : "Deactivated"
        }`,
        icon: "success",
        confirmButtonText: "Okay",
      };
      yield call(showAlert, data);
    }
  } catch (e: any) {
    console.log(e);
    yield put(actions.toggleUserLoader(false));
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  }
};

export const suspendUserAccount = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const data = {
      title: "Caution!",
      text: `Are you sure you want to suspend this user?`,
      icon: "warning",
      confirmButtonText: "Yes",
      denyButtonText: "No",
    };
    const alertData = yield call(showAlert, data);
    if (alertData.isConfirmed) {
      yield put(actions.toggleUserLoader(true));
      yield call(suspendUserAccountAPI, action.payload.id);
      yield put(actions.fetchUserDetails({ id: action.payload.id }));
      yield put(actions.toggleUserLoader(false));
      const data = {
        title: "Success!",
        text: `User successfully suspended.`,
        icon: "success",
        confirmButtonText: "Okay",
      };
      yield call(showAlert, data);
    }
  } catch (e: any) {
    console.log(e);
    yield put(actions.toggleUserLoader(false));
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  }
};

export const getUserAdhocCharge = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    const result = yield call(getAdHocChargesAPI, action.payload.id);
    yield put(actions.setAdhocCharges(result.data.data));
    yield put(actions.toggleUserLoader(false));
  } catch (e: any) {
    console.log(e);
    yield put(actions.toggleUserLoader(false));
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  }
};

export const createUserAdhocCharge = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    yield call(createUpdateAdHocChargesAPI, action.payload);
    yield put(actions.fetchAdhocCharges({ id: action.payload.user_id }));
    yield put(actions.toggleUserLoader(false));
    yield put(actions.setSingleAdhocChargeData({}));
    yield put(actions.toggleAdhocChargeModal(false));
    const data = {
      title: "Success!",
      text: `Adhoc charge ${
        action.payload.id ? "updated" : "created"
      } successfully.`,
      icon: "success",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  } catch (e: any) {
    console.log(e);
    yield put(actions.toggleUserLoader(false));
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  }
};

export const resetBrandInformation = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    yield call(resetBrandAPI, action.payload.id);
    yield put(actions.toggleUserLoader(false));
    yield put(actions.fetchBasicInformation(action.payload));
    const data = {
      title: "Success!",
      text: `Brand data reset successfully.`,
      icon: "success",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  } catch (e: any) {
    console.log(e);
    yield put(actions.toggleUserLoader(false));
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  }
};

export const resetCampaignInformation = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    yield put(actions.toggleUserLoader(true));
    yield call(resetCampaignAPI, action.payload.id);
    yield put(actions.toggleUserLoader(false));
    yield put(actions.fetchBasicInformation(action.payload));
    const data = {
      title: "Success!",
      text: `Campaign data reset successfully.`,
      icon: "success",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  } catch (e: any) {
    console.log(e);
    yield put(actions.toggleUserLoader(false));
    const data = {
      title: "Error!",
      text:
        e?.error?.message ||
        e?.response?.data?.error?.message ||
        e?.response?.data?.errors ||
        e?.errors,
      icon: "error",
      confirmButtonText: "Okay",
    };
    yield call(showAlert, data);
  }
};

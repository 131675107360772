import { createReducer } from 'redux-act';
import {
    signOutUser,
    toggleSideMenu,
    toggleSideMenuHover,
} from '../actions';

export interface ISettingsReducer {
    showSidebar: boolean;
    sidebarHoverState: boolean;
}

const initialState = {
    showSidebar: false,
    sidebarHoverState: false,
};

export const settings = createReducer<ISettingsReducer>({}, initialState);
settings.on(toggleSideMenu, (state: ISettingsReducer) => ({
    ...state,
    showSidebar: !state.showSidebar,
}));
settings.on(toggleSideMenuHover, (state: ISettingsReducer, payload: boolean) => ({
    ...state,
    sidebarHoverState: payload,
}));

settings.on(signOutUser, () => initialState);

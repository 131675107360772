import { IStoreState } from "../types";

export const getIsUserAuth = (state: IStoreState) =>
    state.user.isAuth;

export const getRegistrationResponse = (state: IStoreState) =>
    state.user.registerMessage;
export const getPreDraftMessage = (state: IStoreState) =>
    state.user.pre_draft_message;

export const getCurrentUser = (state: IStoreState) =>
    state.user.user;

export const getRoutes = (state: IStoreState) =>
    state.user.routes || [];

export const getComponentRoutes = (state: IStoreState) =>
    state.user.component_routes || [];

export const getAllPhonenumbers = (state: IStoreState) =>
    state.user.myPhoneNumbers.filter(x => x.status === 'in-use') || [];
export const getSelectedPhoneNumber = (state: IStoreState) =>
    state.user.selectedPhoneNumber || 0;
